<script>
const schemaRequiredTypes = ['card', 'credit_card', 'apple_pay', 'google_pay'];
const optionsList = [
    {value: null, label: 'ANY'},
    {value: 'amex', label: 'Amex'},
    {value: 'diners', label: 'Diners'},
    {value: 'discover', label: 'Discover'},
    {value: 'e-guma', label: 'E-guma'},
    {value: 'invoice', label: 'Invoice'},
    {value: 'jcb', label: 'JCB'},
    {value: 'maestro', label: 'Maestro'},
    {value: 'mastercard', label: 'Mastercard'},
    {value: 'merchant', label: 'Merchant'},
    {value: 'pfcd', label: 'Card'},
    {value: 'pfef', label: 'E-Finance'},
    {value: 'unionpay', label: 'UnionPay'},
    {value: 'unknown', label: 'Unknown'},
    {value: 'visa', label: 'Visa'},
];

const optionsByType = {
    apple_pay: [null, 'amex', 'diners', 'discover', 'jcb', 'maestro', 'mastercard', 'unionpay', 'unknown', 'visa'],
    credit_card: [null, 'amex', 'diners', 'discover', 'jcb', 'maestro', 'mastercard', 'unionpay', 'unknown', 'visa'],
    google_pay: [null, 'amex', 'diners', 'discover', 'jcb', 'maestro', 'mastercard', 'unionpay', 'unknown', 'visa'],
    postfinance: [null, 'pfcd', 'pfef'],
    rebate: [null, 'e-guma', 'merchant'],
};

export function fundingInstrumentSchemaName(type) {
    const option = optionsList.find((o) => o.value === type);

    return option ? option.label : 'Unknown';
}
</script>

<script setup>

import {computed} from "vue";

const model = defineModel();
const props = defineProps({
    type: {
        type: [String, null],
    },
});
const options = computed(() => {
    // FI type specific
    if (optionsByType[props.type]) {
        return optionsByType[props.type].map(scheme => optionsList.find(o => o.value === scheme));
    }

    // ANY
    return optionsList.filter((o) => o.value === null);
});

</script>

<template>
    <select v-model="model">
        <option v-for="option in options" :value="option.value">{{ option.label }}</option>
    </select>
</template>
